<template>
  <b-overlay :show="loading" class="row">
    <div class="col-12 col-lg-6 g c">
      <div class="card">
        <div class="card-header">
          <h4>
            <span v-if="!edit">
              <i class="fa fa-plus"></i>
              اضافة ولي امر جديد
            </span>
            <span v-if="edit">
              <i class="fa fa-edit"></i>
              تعديل ولي الامر
            </span>
          </h4>
        </div>
        <div class="card-body">
          <div class="form-group">
            <label for=""> الاسم </label>
            <input type="text" class="form-control" v-model="parent.name" />
          </div>
          <div class="form-group">
            <label for=""> الجوال </label>
            <input type="text" class="form-control" v-model="parent.phone" />
          </div>
          <div class="form-group">
            <label for=""> كلمة المرور </label>
            <input type="text" class="form-control" v-model="parent.password" />
          </div>
          <div class="form-group">
            <label for="">ملاحظات</label>
            <textarea
              class="form-control"
              v-model="parent.notes"
              rows="3"
            ></textarea>
          </div>
          <div class="col-12 text-center">
            <button class="btn btn-primary" @click="done()">
              <span v-if="!edit">
                اضافة ولي الامر <i class="fa fa-arrow-left"></i> </span
              ><span v-if="edit">
                تعديل ولي الامر <i class="fa fa-arrow-left"></i>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { BFormCheckbox, BOverlay } from "bootstrap-vue";
export default {
  components: {
    BFormCheckbox,
    BOverlay,
  },
  data() {
    return {
      user: JSON.parse(localStorage.getItem("user")),
      loading: true,
      parent: {
        name: "",
        phone: "",
        password: "",
        notes: "",
      },
      edit: false,
    };
  },
  created() {
    var g = this;
    if (!checkPer("parents")) {
      this.$router.push("/per");
      return false;
    }
    if (window.location.hash) {
      g.edit = "true";
      g.loading = true;
      $.post(api + "/user/parents/parent", {
        jwt: g.user.jwt,
        id: window.location.hash.split("#")[1],
      })
        .then(function (r) {
          g.loading = false;
          g.parent = r.response;
        })
        .catch(function (e) {
          console.log(e);
          alert("حدث خطأ..");
          g.loading = false;
        });
    } else {
      g.loading = false;
    }
  },
  methods: {
    checkPer(e) {
      return checkPer(e);
    },
    done() {
      var g = this;
      g.loading = true;
      $.post(api + "/user/parents/add-or-edit", {
        jwt: g.user.jwt,
        parent: g.parent,
        edit: g.edit,
      })
        .then(function (r) {
          g.loading = false;
          g.$toast({
            component: ToastificationContent,
            props: {
              title: "تم بنجاح",
              icon: "CheckIcon",
              variant: "success",
            },
          });
        })
        .catch(function (e) {
          console.log(e);
          alert("حدث خطأ..");
          g.loading = false;
        });
    },
  },
};
</script>

<style>
</style>